.bg-primary {
  background-color: darkgreen;
}

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.animation-shaking {
  animation: tilt-shaking 1.5s linear infinite;
}

.user-select-none {
  user-select: none;
}